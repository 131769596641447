import "scss/index.scss";

import { plugin_registry } from 'nk-plugin-registry';
// import your plugins here.

import "image/js/image";
import "social_links/js/social_links";

plugin_registry.init();

